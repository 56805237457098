<template>
  <div>
    <v-simple-table :class="'tab ' + (noHint ? 'noHint' : '') + ($vuetify.theme.dark ? ' darkTable' : ' lightTable')">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th v-for="(empresa, i) in comparadas" :key="i" :colspan="i == 0 ? 2 : 4" style="border-right: 5px solid var(--v-primary-base)">
            <h1 class="primary--text d-flex justify-center alin-center">{{ empresa.year }}</h1>
          </th>
          <th></th>
        </tr>
        <tr>
          <th style="width:300px"><h1 class="primary--text d-flex justify-end align-center"><v-btn color="primary" fab x-small @click.stop="noHint = !noHint"><v-icon>{{ noHint ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon></v-btn></h1></th>
          <th style="width:300px"></th>
          <th style="width:150px;border-right: 5px solid var(--v-primary-base)" :title="empresa.nombre" v-for="(empresa,i) in comparadas" :colspan="i == 0 ? 2 : 4">
            <div class="d-flex justify-space-between align-center">
              <span :style="'width: ' + (i == 0 ? '100' : '300') + 'px; overflow: hidden; text-overflow: ellipsis;white-space: nowrap;display: block'">
                {{ empresa.nombre }}
              </span>
              <span style="width: 50px">
                <v-btn class="me-1 ms-auto" fab x-small color="error" @click.stop="comparadas.splice(i,1)"><v-icon>mdi-delete</v-icon></v-btn>
              </span>
            </div>
          </th>
          <th class="primary--text text-center"><h1 style="white-space: nowrap;" class="d-flex justify-space-between alin-center">{{ nuevo.nombreCustom ? nuevo.nombreCustom : 'NUEVO'}}<span><v-btn class="me-1" fab x-small color="error" @click.stop="nuevo = {}"><v-icon>mdi-delete</v-icon></v-btn><v-btn @click.stop="saveNewEstudio" fab x-small color="primary"><v-icon>mdi-floppy</v-icon></v-btn></span></h1></th>
        </tr>
        <tr>
          <th><h2 class="primary--text">CUENTA PyG</h2></th>
          <th>NOMBRE E-INFORMA</th>
          <template v-for="(empresa,i) in comparadas">
            <th v-if="editingName === empresa.cif" style="width: 100px;">
              <div class="d-flex justify-space-between align-center">
                <v-text-field v-model="empresa['nombreCustom']" dense hide-details=""></v-text-field>
                <v-btn @click.stop="update('nombreCustom', empresa)" fab x-small color="info"><v-icon>mdi-floppy</v-icon></v-btn>
              </div>
            </th>
            <th v-else style="width: 100px;">
              <div class="d-flex justify-space-between align-center">
                <span style="width: 100px; overflow: hidden; text-overflow: ellipsis;white-space: nowrap;display: block">
                  {{ empresa.nombreCustom }}
                </span>
                <v-btn @click.stop="editingName = empresa.cif" fab x-small color="primary"><v-icon>mdi-pencil</v-icon></v-btn>
              </div>
            </th>
            <th :style="'width: 50px;text-align: center;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">RATIOS</th>
            <template v-if="i != 0">
              <th style="width: 100px;text-align: center;border-right: 5px solid var(--v-primary-base)" colspan="2">DIF</th>
            </template>
          </template>
          <th><v-text-field v-model="nuevo.nombreCustom" dense hide-details=""></v-text-field></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Ventas</td>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">40100 - 1. Importe neto de la cifra de negocios</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['40100']" dense hide-details=""></v-text-field></td> -->
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['40100'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td class="font-weight-bold" :style="'font-size: large;white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">{{ Math.round(calculatePercentage(empresa, ['40100'], ['40100','40500'])) + data.pnt }} %</td>
            <template v-if="i != 0">
              <template v-if="(comparadas[0]['40100'] || 0) >= Math.round(empresa['40100'] || 0)">
                <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((comparadas[0]['40100'] || 0) - Math.round(empresa['40100'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((((comparadas[0]['40100'] || 0) - Math.round(empresa['40100'] || 0)) / (Math.round(empresa['40100'] || 1))) * 100) }} %</td>
              </template>
              <template v-else>
                <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((comparadas[0]['40100'] || 0) - Math.round(empresa['40100'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((((comparadas[0]['40100'] || 0) - Math.round(empresa['40100'] || 0)) / (Math.round(empresa['40100'] || 1))) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['40100']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Aprovisionamientos</td>
          <td style="white-space: nowrap;">40400 - 4. Aprovisionamientos</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['40400']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['40400'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')" class="error--text">{{ Math.round(calculatePercentage(empresa, ['40400'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="(comparadas[0]['40400'] || 0) >= Math.round(empresa['40400'] || 0)">
                <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((comparadas[0]['40400'] || 0) - Math.round(empresa['40400'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((((comparadas[0]['40400'] || 0) - Math.round(empresa['40400'] || 0)) / (Math.round(empresa['40400'] || 1))) * 100) }} %</td>
              </template>
              <template v-else>
                <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((comparadas[0]['40400'] || 0) - Math.round(empresa['40400'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((((comparadas[0]['40400'] || 0) - Math.round(empresa['40400'] || 0)) / (Math.round(empresa['40400'] || 1))) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['40400']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Margen Bruto</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(empresa, ['40400', '40100'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td class="font-weight-bold" :style="'font-size: large;white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">{{ Math.round(calculatePercentage(empresa, ['40400', '40100'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(calculateSum(comparadas[0], ['40400', '40100'])) >= Math.round(calculateSum(empresa, ['40400', '40100']))">
                <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100'])) - Math.round(calculateSum(empresa, ['40400', '40100']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(calculateSum(comparadas[0], ['40400', '40100'])) - Math.round(calculateSum(empresa, ['40400', '40100']))) / (Math.round(Math.round(calculateSum(empresa, ['40400', '40100'])) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100'])) - Math.round(calculateSum(empresa, ['40400', '40100']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(calculateSum(comparadas[0], ['40400', '40100'])) - Math.round(calculateSum(empresa, ['40400', '40100']))) / (Math.round(Math.round(calculateSum(empresa, ['40400', '40100'])) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(calculateSum(nuevo, ['40400', '40100'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Otros Ingresos Expl.</td>
          <td style="white-space: nowrap;">40500 - 5. Otros ingresos de explotación</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['40500']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['40500'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">{{ Math.round(calculatePercentage(empresa, ['40500'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['40500'] || 0) >= Math.round(empresa['40500'] || 0)">
                <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['40500'] || 0) - Math.round(empresa['40500'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['40500'] || 0) - Math.round(empresa['40500'] || 0)) / (Math.round(empresa['40500'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['40500'] || 0) - Math.round(empresa['40500'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['40500'] || 0) - Math.round(empresa['40500'] || 0)) / (Math.round(empresa['40500'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['40500']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Gastos Personal</td>
          <td style="white-space: nowrap;">40600 - 6. Gastos de personal</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['40600']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['40600'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')" class="error--text">{{ Math.round(calculatePercentage(empresa, ['40600'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['40600'] || 0) >= Math.round(empresa['40600'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(comparadas[0]['40600'] || 0) - Math.round(empresa['40600'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['40600'] || 0) - Math.round(empresa['40600'] || 0)) / (Math.round(empresa['40600'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(comparadas[0]['40600'] || 0) - Math.round(empresa['40600'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['40600'] || 0) - Math.round(empresa['40600'] || 0)) / (Math.round(empresa['40600'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['40600']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">O.C.E. y GG</td>
          <td style="white-space: nowrap;">40700 - 7. Otros gastos de explotación</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['40700']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['40700'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')" class="error--text">{{ Math.round(calculatePercentage(empresa, ['40700'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['40700'] || 0) >= Math.round(empresa['40700'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(comparadas[0]['40700'] || 0) - Math.round(empresa['40700'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['40700'] || 0) - Math.round(empresa['40700'] || 0)) / (Math.round(empresa['40700'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(comparadas[0]['40700'] || 0) - Math.round(empresa['40700'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['40700'] || 0) - Math.round(empresa['40700'] || 0)) / (Math.round(empresa['40700'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['40700']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Imputación Subv. Inm No financ.</td>
          <td style="white-space: nowrap;">40900 - 9. Imputación de subvenciones de inmovilizado no financiero y otras</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['40900']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['40900'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">{{ Math.round(calculatePercentage(empresa, ['40900'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['40900'] || 0) >= Math.round(empresa['40900'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['40900'] || 0) - Math.round(empresa['40900'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['40900'] || 0) - Math.round(empresa['40900'] || 0)) / (Math.round(empresa['40900'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['40900'] || 0) - Math.round(empresa['40900'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['40900'] || 0) - Math.round(empresa['40900'] || 0)) / (Math.round(empresa['40900'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['40900']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Otros Resultados / Resultados Extraord.</td>
          <td style="white-space: nowrap;">413001 - 2. Otros resultados</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['41300']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['41300'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">{{ Math.round(calculatePercentage(empresa, ['41300'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['41300'] || 0) >= Math.round(empresa['41300'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['41300'] || 0) - Math.round(empresa['41300'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['41300'] || 0) - Math.round(empresa['41300'] || 0)) / (Math.round(empresa['41300'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['41300'] || 0) - Math.round(empresa['41300'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['41300'] || 0) - Math.round(empresa['41300'] || 0)) / (Math.round(empresa['41300'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['41300']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">EBITDA</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td class="font-weight-bold" :style="'font-size: large;white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">{{ Math.round(calculatePercentage(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300'], ['40100'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])) >= Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300']))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300']))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300']))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(calculateSum(nuevo, ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Amortización </td>
          <td style="white-space: nowrap;">40800 - 8. Amortización del inmovilizado</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['40800']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['40800'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')" class="error--text">{{ Math.round(calculatePercentage(empresa, ['40800'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['40800'] || 0) >= Math.round(empresa['40800'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(comparadas[0]['40800'] || 0) - Math.round(empresa['40800'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['40800'] || 0) - Math.round(empresa['40800'] || 0)) / (Math.round(empresa['40800'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(comparadas[0]['40800'] || 0) - Math.round(empresa['40800'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['40800'] || 0) - Math.round(empresa['40800'] || 0)) / (Math.round(empresa['40800'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['40800']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">EBIT - Rtdo Explotación </td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">{{ Math.round(calculatePercentage(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])) >= Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800']))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800']))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800']))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;">{{ Math.round(calculateSum(nuevo, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Resultado Financiero</td>
          <td style="white-space: nowrap;">49200 - B)  RESULTADO FINANCIERO (13 + 14 + 15 + 16 + 17 + 18)</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['49200']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['49200'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')" class="error--text">{{ Math.round(calculatePercentage(empresa, ['49200'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['49200'] || 0) >= Math.round(empresa['49200'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(comparadas[0]['49200'] || 0) - Math.round(empresa['49200'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['49200'] || 0) - Math.round(empresa['49200'] || 0)) / (Math.round(empresa['49200'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(comparadas[0]['49200'] || 0) - Math.round(empresa['49200'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['49200'] || 0) - Math.round(empresa['49200'] || 0)) / (Math.round(empresa['49200'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['49200']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">BAI (BAT)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td class="font-weight-bold" :style="'font-size: large;white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">{{ Math.round(calculatePercentage(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])) >= Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200']))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200']))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200']))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(calculateSum(nuevo, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">IS Sociedades</td>
          <td style="white-space: nowrap;">419001 - 9. Impuestos sobre beneficios</td>
          <template v-for="(empresa,i) in comparadas">
            <td style="border-right: 1px solid rgb(144, 144, 144)" v-show="editingIs !== empresa.cif" class="d-flex justify-space-between align-center">{{ Math.round(empresa['41900'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')" class="error--text">{{ Math.round(calculatePercentage(empresa, ['41900'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['41900'] || 0) >= Math.round(empresa['41900'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(comparadas[0]['41900'] || 0) - Math.round(empresa['41900'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['41900'] || 0) - Math.round(empresa['41900'] || 0)) / (Math.round(empresa['41900'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(comparadas[0]['41900'] || 0) - Math.round(empresa['41900'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['41900'] || 0) - Math.round(empresa['41900'] || 0)) / (Math.round(empresa['41900'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['41900']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">BENEFICIO NETO (Rtdo Ejercicio)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td class="font-weight-bold" :style="'font-size: large;white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">{{ Math.round(calculatePercentage(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'], ['40100','40500'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) >= Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(calculateSum(nuevo, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th><h2 class="primary--text">PUNTO MUERTO</h2></th>
          <th>NOMBRE E-INFORMA</th>
          <template v-for="(empresa,i) in comparadas">
            <th>
              <span :title="empresa.nombre" style="width: 100px; overflow: hidden; text-overflow: ellipsis;white-space: nowrap;display: block">
                {{ empresa.nombreCustom }} - {{ empresa.year }}
              </span>
            </th>
            <th :style="'width: 50px;text-align: center;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">RATIOS</th>
            <template v-if="i != 0">
              <th style="width: 100px;text-align: center;border-right: 5px solid var(--v-primary-base)" colspan="2">DIF</th>
            </template>
          </template>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Punto Muerto (€)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(-(calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(Math.round(calculatePercentage(empresa, ['40400'], ['40100','40500'])) / 100))) || 1)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(-(calculateSum(comparadas[0], ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(comparadas[0], ['40400'], ['40100','40500'])))) || 1)) >= Math.round(-(calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(empresa, ['40400'], ['40100','40500'])))) || 1))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(-(calculateSum(comparadas[0], ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(comparadas[0], ['40400'], ['40100','40500'])))) || 1)) - Math.round(-(calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(empresa, ['40400'], ['40100','40500'])))) || 1))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(-(calculateSum(comparadas[0], ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(comparadas[0], ['40400'], ['40100','40500'])))) || 1)) - Math.round(-(calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(empresa, ['40400'], ['40100','40500'])))) || 1))) / (Math.round(-(calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(empresa, ['40400'], ['40100','40500'])))) || 1)) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(-(calculateSum(comparadas[0], ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(comparadas[0], ['40400'], ['40100','40500'])))) || 1)) - Math.round(-(calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(empresa, ['40400'], ['40100','40500'])))) || 1))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(-(calculateSum(comparadas[0], ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(comparadas[0], ['40400'], ['40100','40500'])))) || 1)) - Math.round(-(calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(empresa, ['40400'], ['40100','40500'])))) || 1))) / (Math.round(-(calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(empresa, ['40400'], ['40100','40500'])))) || 1)) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(-(calculateSum(nuevo, ['40800', '40700', '40600'])) / ((1-(-(calculatePercentage(nuevo, ['40400'], ['40100','40500'])))) || 1)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th><h2 class="primary--text">CASH FLOWS</h2></th>
          <th>NOMBRE E-INFORMA</th>
          <template v-for="(empresa,i) in comparadas">
            <th>
              <span :title="empresa.nombre" style="width: 100px; overflow: hidden; text-overflow: ellipsis;white-space: nowrap;display: block">
                {{ empresa.nombreCustom }} - {{ empresa.year }}
              </span>
            </th>
            <th :style="'width: 50px;text-align: center;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">RATIOS</th>
            <template v-if="i != 0">
              <th style="width: 100px;text-align: center;border-right: 5px solid var(--v-primary-base)" colspan="2">DIF</th>
            </template>
          </template>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="white-space: nowrap;">Beneficio Neto</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) >= Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;">{{ Math.round(calculateSum(nuevo, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Amortizaciones</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(-(empresa['40800'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(-(comparadas[0]['40800'] || 0)) >= Math.round(-(empresa['40800'] || 0))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(-(comparadas[0]['40800'] || 0)) - Math.round(-(empresa['40800'] || 0))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(-(comparadas[0]['40800'] || 0)) - Math.round(-(empresa['40800'] || 0))) / (Math.round(-(empresa['40800'] || 0)) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(-(comparadas[0]['40800'] || 0)) - Math.round(-(empresa['40800'] || 0))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(-(comparadas[0]['40800'] || 0)) - Math.round(-(empresa['40800'] || 0))) / (Math.round(-(empresa['40800'] || 0)) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;">{{ Math.round(-(nuevo['40800'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Imputación Subv. Inm No financ.</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(-(empresa['40900'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(-(comparadas[0]['40900'] || 0)) >= Math.round(-(empresa['40900'] || 0))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(-(comparadas[0]['40900'] || 0)) - Math.round(-(empresa['40900'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(-(comparadas[0]['40900'] || 0)) - Math.round(-(empresa['40900'] || 0))) / (Math.round(-(empresa['40900'] || 0)) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(-(comparadas[0]['40900'] || 0)) - Math.round(-(empresa['40900'] || 0))) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(-(comparadas[0]['40900'] || 0)) - Math.round(-(empresa['40900'] || 0))) / (Math.round(-(empresa['40900'] || 0)) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;">{{ Math.round(-(nuevo['40900'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">IS Sociedades (bases negativas)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="border-right: 1px solid rgb(144, 144, 144)" v-if="editingIs === empresa.cif">
              <div class="d-flex justify-space-between align-center">
                <v-text-field type="number" class="noArrow" v-model="empresa['isSociedades']" dense hide-details=""></v-text-field>
                <v-btn @click.stop="update('isSociedades', empresa)" fab x-small color="info"><v-icon>mdi-floppy</v-icon></v-btn>
              </div>
            </td>
            <td style="border-right: 1px solid rgb(144, 144, 144)" v-else class="d-flex justify-space-between align-center">
              {{ Math.round(empresa['isSociedades'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €
              <v-btn @click.stop="editingIs = empresa.cif" fab x-small color="primary"><v-icon>mdi-pencil</v-icon></v-btn>
            </td>
            <td :style="(i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['isSociedades'] || 0) >= Math.round(empresa['isSociedades'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round((Math.round(comparadas[0]['isSociedades'] || 0) - Math.round(empresa['isSociedades'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['isSociedades'] || 0) - Math.round(empresa['isSociedades'] || 0)) / (Math.round(empresa['isSociedades'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round((Math.round(comparadas[0]['isSociedades'] || 0) - Math.round(empresa['isSociedades'] || 0)) * -1).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['isSociedades'] || 0) - Math.round(empresa['isSociedades'] || 0)) / (Math.round(empresa['isSociedades'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['isSociedades']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">FCF OPERATIVO</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="
                    Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                      -(comparadas[0]['40800'] || 0) +
                      -(comparadas[0]['40900'] || 0)) >= 
                    Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                      -(empresa['40800'] || 0) +
                      -(empresa['40900'] || 0))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(comparadas[0]['40800'] || 0) +
                    -(comparadas[0]['40900'] || 0)) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(comparadas[0]['40800'] || 0) +
                    -(comparadas[0]['40900'] || 0)) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                      -(comparadas[0]['40800'] || 0) +
                      -(comparadas[0]['40900'] || 0)) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                      -(empresa['40800'] || 0) +
                      -(empresa['40900'] || 0))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                      -(comparadas[0]['40800'] || 0) +
                      -(comparadas[0]['40900'] || 0)) - Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0))) / (Math.round(calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(calculateSum(nuevo, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(nuevo['40800'] || 0) +
                    -(nuevo['40900'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Deuda (amort. Principal) - media 8a</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(empresa, ['31220', '32300']) / 8).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td class="font-weight-bold error--text" :style="'font-size: large;white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">{{ Math.round(calculatePercentage(empresa, ['31220', '32300'], ['40100','40500']) / 8).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round(calculateSum(comparadas[0], ['31220', '32300'])) >= Math.round(calculateSum(empresa, ['31220', '32300']))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['31220', '32300'])) - Math.round(calculateSum(empresa, ['31220', '32300']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['31220', '32300'])) - Math.round(calculateSum(empresa, ['31220', '32300']))) / (Math.round(calculateSum(empresa, ['31220', '32300'])) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['31220', '32300'])) - Math.round(calculateSum(empresa, ['31220', '32300']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['31220', '32300'])) - Math.round(calculateSum(empresa, ['31220', '32300']))) / (Math.round(calculateSum(empresa, ['31220', '32300'])) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round(calculateSum(nuevo, ['31220', '32300'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">FREE CASH FLOWS</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round((calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)) - Math.round(calculateSum(empresa, ['31220', '32300']) / 8)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td class="font-weight-bold" :style="'font-size: large;white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">{{ Math.round(((calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)) - Math.round(calculateSum(empresa, ['31220', '32300']) / 8)) / (((empresa['40100'] || 0) + (empresa['40500'] || 0)) || 1)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <template v-if="i != 0">
              <template v-if="Math.round((calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(comparadas[0]['40800'] || 0) +
                    -(comparadas[0]['40900'] || 0)) - (comparadas[0]['isSociedades'] || 0)) >= Math.round((calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)) - Math.round(calculateSum(empresa, ['31220', '32300']) / 8))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round((calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(comparadas[0]['40800'] || 0) +
                    -(comparadas[0]['40900'] || 0)) - (comparadas[0]['isSociedades'] || 0)) - Math.round((calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)) - Math.round(calculateSum(empresa, ['31220', '32300']) / 8))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round((calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(comparadas[0]['40800'] || 0) +
                    -(comparadas[0]['40900'] || 0)) - (comparadas[0]['isSociedades'] || 0)) - Math.round((calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)) - Math.round(calculateSum(empresa, ['31220', '32300']) / 8))) / (Math.round((calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)) - Math.round(calculateSum(empresa, ['31220', '32300']) / 8)) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round((calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(comparadas[0]['40800'] || 0) +
                    -(comparadas[0]['40900'] || 0)) - (comparadas[0]['isSociedades'] || 0)) - Math.round((calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)) - (empresa['isSociedades'] || 0))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round((calculateSum(comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(comparadas[0]['40800'] || 0) +
                    -(comparadas[0]['40900'] || 0)) - (comparadas[0]['isSociedades'] || 0)) - Math.round((calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)) - (empresa['isSociedades'] || 0))) / (Math.round((calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(empresa['40800'] || 0) +
                    -(empresa['40900'] || 0)) - (empresa['isSociedades'] || 0)) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">{{ Math.round((calculateSum(nuevo, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) +
                    -(nuevo['40800'] || 0) +
                    -(nuevo['40900'] || 0)) - (nuevo['isSociedades'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th><h2 class="primary--text">BALANCE</h2></th>
          <th>NOMBRE E-INFORMA</th>
          <template v-for="(empresa,i) in comparadas">
            <th>
              <span :title="empresa.nombre" style="width: 100px; overflow: hidden; text-overflow: ellipsis;white-space: nowrap;display: block">
                {{ empresa.nombreCustom }} - {{ empresa.year }}
              </span>
            </th>
            <th :style="'width: 50px;text-align: center;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">RATIOS</th>
            <template v-if="i != 0">
              <th style="width: 100px;text-align: center;border-right: 5px solid var(--v-primary-base)" colspan="2">DIF</th>
            </template>
          </template>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Efectivo (y otros act. líquidos)</td>
          <td style="white-space: nowrap;">12700 - VI. Efectivo y otros activos líquidos equivalentes</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['12700']" dense hide-details=""></v-text-field></td> -->
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['12700'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['12700'] || 0) >= Math.round(empresa['12700'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['12700'] || 0) - Math.round(empresa['12700'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['12700'] || 0) - Math.round(empresa['12700'] || 0)) / (Math.round(empresa['12700'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['12700'] || 0) - Math.round(empresa['12700'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['12700'] || 0) - Math.round(empresa['12700'] || 0)) / (Math.round(empresa['12700'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['12700']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Activo NO Corriente</td>
          <td style="white-space: nowrap;">11000 - A) ACTIVO NO CORRIENTE</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['11000']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['11000'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['11000'] || 0) >= Math.round(empresa['11000'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['11000'] || 0) - Math.round(empresa['11000'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['11000'] || 0) - Math.round(empresa['11000'] || 0)) / (Math.round(empresa['11000'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['11000'] || 0) - Math.round(empresa['11000'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['11000'] || 0) - Math.round(empresa['11000'] || 0)) / (Math.round(empresa['11000'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['11000']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Existencias</td>
          <td style="white-space: nowrap;">12200 - I.  Existencias</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['12200']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['12200'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['12200'] || 0) >= Math.round(empresa['12200'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['12200'] || 0) - Math.round(empresa['12200'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['12200'] || 0) - Math.round(empresa['12200'] || 0)) / (Math.round(empresa['12200'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['12200'] || 0) - Math.round(empresa['12200'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['12200'] || 0) - Math.round(empresa['12200'] || 0)) / (Math.round(empresa['12200'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['12200']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Clientes (Deudores)</td>
          <td style="white-space: nowrap;">12300 - II. Deudores comerciales y otras cuentas a cobrar</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['12300']" dense hide-details=""></v-text-field></td> -->
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['12300'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['12300'] || 0) >= Math.round(empresa['12300'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['12300'] || 0) - Math.round(empresa['12300'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['12300'] || 0) - Math.round(empresa['12300'] || 0)) / (Math.round(empresa['12300'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['12300'] || 0) - Math.round(empresa['12300'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['12300'] || 0) - Math.round(empresa['12300'] || 0)) / (Math.round(empresa['12300'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['12300']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Patrimonio Neto</td>
          <td style="white-space: nowrap;">20000 - A) PATRIMONIO NETO</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['20000']" dense hide-details=""></v-text-field></td> -->
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['20000'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['20000'] || 0) >= Math.round(empresa['20000'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['20000'] || 0) - Math.round(empresa['20000'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['20000'] || 0) - Math.round(empresa['20000'] || 0)) / (Math.round(empresa['20000'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['20000'] || 0) - Math.round(empresa['20000'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['20000'] || 0) - Math.round(empresa['20000'] || 0)) / (Math.round(empresa['20000'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['20000']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Rtdo Ejercicios Anteriores</td>
          <td style="white-space: nowrap;">21500 - V. Resultados de ejercicios anteriores</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['21500']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['21500'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['21500'] || 0) >= Math.round(empresa['21500'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['21500'] || 0) - Math.round(empresa['21500'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['21500'] || 0) - Math.round(empresa['21500'] || 0)) / (Math.round(empresa['21500'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['21500'] || 0) - Math.round(empresa['21500'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['21500'] || 0) - Math.round(empresa['21500'] || 0)) / (Math.round(empresa['21500'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['21500']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Pasivo NO Corriente</td>
          <td style="white-space: nowrap;">31000 - B) PASIVO NO CORRIENTE</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['31000']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['31000'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['31000'] || 0) >= Math.round(empresa['31000'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['31000'] || 0) - Math.round(empresa['31000'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['31000'] || 0) - Math.round(empresa['31000'] || 0)) / (Math.round(empresa['31000'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['31000'] || 0) - Math.round(empresa['31000'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['31000'] || 0) - Math.round(empresa['31000'] || 0)) / (Math.round(empresa['31000'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['31000']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Deudas LP (con e.f.)</td>
          <td style="white-space: nowrap;">31220 - 1. Deudas con entidades de crédito</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['31220']" dense hide-details=""></v-text-field></td> -->
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['31220'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['31220'] || 0) >= Math.round(empresa['31220'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['31220'] || 0) - Math.round(empresa['31220'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['31220'] || 0) - Math.round(empresa['31220'] || 0)) / (Math.round(empresa['31220'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['31220'] || 0) - Math.round(empresa['31220'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['31220'] || 0) - Math.round(empresa['31220'] || 0)) / (Math.round(empresa['31220'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['31220']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Otras Deudas LP + Deudas empresas grupo</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(calculateSum(comparadas[0], ['31300', '31400', '31500', '31600', '31700'])) >= Math.round(calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700']))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['31300', '31400', '31500', '31600', '31700'])) - Math.round(calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['31300', '31400', '31500', '31600', '31700'])) - Math.round(calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700']))) / (Math.round(calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700'])) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(calculateSum(comparadas[0], ['31300', '31400', '31500', '31600', '31700'])) - Math.round(calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700']))).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(calculateSum(comparadas[0], ['31300', '31400', '31500', '31600', '31700'])) - Math.round(calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700']))) / (Math.round(calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700'])) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;">{{ Math.round(calculateSum(nuevo, ['31300', '31400', '31500', '31600', '31700'])).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;">Deudas CP</td>
          <td style="white-space: nowrap;">32300 - II. Deudas a corto plazo</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['32300']" dense hide-details=""></v-text-field></td> -->
            <td class="font-weight-bold" style="font-size: large;white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['32300'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['32300'] || 0) >= Math.round(empresa['32300'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['32300'] || 0) - Math.round(empresa['32300'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['32300'] || 0) - Math.round(empresa['32300'] || 0)) / (Math.round(empresa['32300'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['32300'] || 0) - Math.round(empresa['32300'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['32300'] || 0) - Math.round(empresa['32300'] || 0)) / (Math.round(empresa['32300'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td class="font-weight-bold" style="font-size: large;white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['32300']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Proveedores</td>
          <td style="white-space: nowrap;">32580 - 1.  Proveedores</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['32580']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['32580'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['32580'] || 0) >= Math.round(empresa['32580'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['32580'] || 0) - Math.round(empresa['32580'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['32580'] || 0) - Math.round(empresa['32580'] || 0)) / (Math.round(empresa['32580'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['32580'] || 0) - Math.round(empresa['32580'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['32580'] || 0) - Math.round(empresa['32580'] || 0)) / (Math.round(empresa['32580'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['32480']" dense hide-details=""></v-text-field></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Otros Acreedores</td>
          <td style="white-space: nowrap;">32590 - 2.  Otros acreedores</td>
          <template v-for="(empresa,i) in comparadas">
            <!-- <td v-show="editingIs === empresa.cif"><v-text-field type="number" class="noArrow" v-model="empresa['32590']" dense hide-details=""></v-text-field></td> -->
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['32590'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['32590'] || 0) >= Math.round(empresa['32590'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['32590'] || 0) - Math.round(empresa['32590'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['32590'] || 0) - Math.round(empresa['32590'] || 0)) / (Math.round(empresa['32590'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['32590'] || 0) - Math.round(empresa['32590'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['32590'] || 0) - Math.round(empresa['32590'] || 0)) / (Math.round(empresa['32590'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;"><v-text-field type="number" class="noArrow" v-model="nuevo['32590']" dense hide-details=""></v-text-field></td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th><h2 class="primary--text">RATIOS</h2></th>
          <th>NOMBRE E-INFORMA</th>
          <template v-for="(empresa,i) in comparadas">
            <th>
              <span :title="empresa.nombre" style="width: 100px; overflow: hidden; text-overflow: ellipsis;white-space: nowrap;display: block">
                {{ empresa.nombreCustom }} - {{ empresa.year }}
              </span>
            </th>
            <th :style="'width: 50px;text-align: center;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')">RATIOS</th>
            <template v-if="i != 0">
              <th style="width: 100px;text-align: center;border-right: 5px solid var(--v-primary-base)" colspan="2">DIF</th>
            </template>
          </template>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="primary--text">ratios equilibrio y liquidez</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td colspan="2" :style="(i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <td colspan="2" style="width: 50px;border-right:5px solid var(--v-primary-base)"></td>
            </template>
          </template>
          <td style="white-space: nowrap;"></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Fondo Maniobra (FM &gt; 0)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round((empresa['11000'] || 0) + (empresa['20000'] || 0) - (empresa['31000'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round((comparadas[0]['11000'] || 0) + (comparadas[0]['20000'] || 0) - (comparadas[0]['31000'] || 0)) >= Math.round((empresa['11000'] || 0) + (empresa['20000'] || 0) - (empresa['31000'] || 0))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round((comparadas[0]['11000'] || 0) + (comparadas[0]['20000'] || 0) - (comparadas[0]['31000'] || 0)) - Math.round((empresa['11000'] || 0) + (empresa['20000'] || 0) - (empresa['31000'] || 0))) / (Math.round((empresa['11000'] || 0) + (empresa['20000'] || 0) - (empresa['31000'] || 0)) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round((comparadas[0]['11000'] || 0) + (comparadas[0]['20000'] || 0) - (comparadas[0]['31000'] || 0)) - Math.round((empresa['11000'] || 0) + (empresa['20000'] || 0) - (empresa['31000'] || 0))) / (Math.round((empresa['11000'] || 0) + (empresa['20000'] || 0) - (empresa['31000'] || 0)) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;">{{ Math.round((nuevo['11000'] || 0) + (nuevo['20000'] || 0) - (nuevo['31000'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Ratio Solvencia (objetivo &gt;1)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)" :class="Math.round((empresa['10000'] || 0) / (empresa['32000'] || 1)) >= 1 ? 'info--text' : 'error--text'">{{ Math.round((empresa['10000'] || 0) / (empresa['32000'] || 1)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round((comparadas[0]['10000'] || 0) / (comparadas[0]['32000'] || 1)) >= Math.round((empresa['10000'] || 0) / (empresa['32000'] || 1))">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round((comparadas[0]['10000'] || 0) / (comparadas[0]['32000'] || 1)) - Math.round((empresa['10000'] || 0) / (empresa['32000'] || 1))) / (Math.round((empresa['10000'] || 0) / (empresa['32000'] || 1)) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round((comparadas[0]['10000'] || 0) / (comparadas[0]['32000'] || 1)) - Math.round((empresa['10000'] || 0) / (empresa['32000'] || 1))) / (Math.round((empresa['10000'] || 0) / (empresa['32000'] || 1)) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td :class="Math.round((nuevo['10000'] || 0) / (nuevo['32000'] || 1)) >= 1 ? 'info--text' : 'error--text'">{{ Math.round((nuevo['10000'] || 0) / (nuevo['32000'] || 1)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Ratio de Liquidez (objetivo &gt;1) (%)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)" :class="Math.round((empresa['11010193606'] || 0) / 100) >= 1 ? 'info--text' : 'error--text'">{{ Math.round((empresa['11010193606'] || 0) / 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round((comparadas[0]['11010193606'] || 0) / 100) >= Math.round((empresa['11010193606'] || 0) / 100)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round((comparadas[0]['11010193606'] || 0) / 100) - Math.round((empresa['11010193606'] || 0) / 100)) / (Math.round((empresa['11010193606'] || 0) / 100) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round((comparadas[0]['11010193606'] || 0) / 100) - Math.round((empresa['11010193606'] || 0) / 100)) / (Math.round((empresa['11010193606'] || 0) / 100) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td :class="Math.round((nuevo['11010193606'] || 0) / 100) >= 1 ? 'info--text' : 'error--text'">{{ Math.round((nuevo['11010193606'] || 0) / 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td class="primary--text">ratios endeudamiento</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td colspan="2" :style="(i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <td colspan="2" style="width: 50px;border-right:5px solid var(--v-primary-base)"></td>
            </template>
          </template>
          <td style="white-space: nowrap;"></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Porcentaje Endeudamiento (%) (obj 40%-60%)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)" :class="Math.round(empresa['11010193608'] || 0) >= 40 && Math.round(empresa['11010193608'] || 0) <= 60 ? 'info--text' : 'error--text'">{{ Math.round(empresa['11010193608'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['11010193608'] || 0) >= Math.round(empresa['11010193608'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193608'] || 0) - Math.round(empresa['11010193608'] || 0)) / (Math.round(empresa['11010193608'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193608'] || 0) - Math.round(empresa['11010193608'] || 0)) / (Math.round(empresa['11010193608'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td :class="Math.round(nuevo['11010193608'] || 0) >= 40 && Math.round(nuevo['11010193608'] || 0) <= 60 ? 'info--text' : 'error--text'">{{ Math.round(nuevo['11010193608'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Cobertura Servicio de la Deuda (obj &lt;4)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="border-right: 1px solid rgb(144, 144, 144)" :class="Math.round(empresa['11010193610'] || 0) <= 4 ? 'info--text' : 'error--text'">{{ Math.round(empresa['11010193610'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['11010193610'] || 0) >= Math.round(empresa['11010193610'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193610'] || 0) - Math.round(empresa['11010193610'] || 0)) / (Math.round(empresa['11010193610'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193610'] || 0) - Math.round(empresa['11010193610'] || 0)) / (Math.round(empresa['11010193610'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td :class="Math.round(nuevo['11010193610'] || 0) <= 4 ? 'info--text' : 'error--text'">{{ Math.round(nuevo['11010193610'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td class="primary--text">ratios operativos</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td colspan="2" :style="(i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <td colspan="2" style="width: 50px;border-right:5px solid var(--v-primary-base)"></td>
            </template>
          </template>
          <td style="white-space: nowrap;"></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Ventas por Empleado (media)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['11010193615'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['11010193615'] || 0) >= Math.round(empresa['11010193615'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['11010193615'] || 0) - Math.round(empresa['11010193615'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193615'] || 0) - Math.round(empresa['11010193615'] || 0)) / (Math.round(empresa['11010193615'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['11010193615'] || 0) - Math.round(empresa['11010193615'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193615'] || 0) - Math.round(empresa['11010193615'] || 0)) / (Math.round(empresa['11010193615'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;">{{ Math.round(nuevo['11010193615'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Coste por Empleado (medio)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['11010193616'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['11010193616'] || 0) >= Math.round(empresa['11010193616'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(Math.round(comparadas[0]['11010193616'] || 0) - Math.round(empresa['11010193616'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193616'] || 0) - Math.round(empresa['11010193616'] || 0)) / (Math.round(empresa['11010193616'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(Math.round(comparadas[0]['11010193616'] || 0) - Math.round(empresa['11010193616'] || 0)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193616'] || 0) - Math.round(empresa['11010193616'] || 0)) / (Math.round(empresa['11010193616'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;">{{ Math.round(nuevo['11010193616'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
        </tr>
        <tr>
          <td class="primary--text">ratios resultados</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td colspan="2" :style="(i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <td colspan="2" style="width: 50px;border-right:5px solid var(--v-primary-base)"></td>
            </template>
          </template>
          <td style="white-space: nowrap;"></td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">ROA - rentabilidad económica (%) (obj + alto)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['11010193619'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['11010193619'] || 0) >= Math.round(empresa['11010193619'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193619'] || 0) - Math.round(empresa['11010193619'] || 0)) / (Math.round(empresa['11010193619'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193619'] || 0) - Math.round(empresa['11010193619'] || 0)) / (Math.round(empresa['11010193619'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;">{{ Math.round(nuevo['11010193619'] || 0) }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">Rentabilidad de Explotación (%) (obj + alto)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['11010193620'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['11010193620'] || 0) >= Math.round(empresa['11010193620'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193620'] || 0) - Math.round(empresa['11010193620'] || 0)) / (Math.round(empresa['11010193620'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193620'] || 0) - Math.round(empresa['11010193620'] || 0)) / (Math.round(empresa['11010193620'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;">{{ Math.round(nuevo['11010193620'] || 0) }}</td>
        </tr>
        <tr>
          <td style="white-space: nowrap;">ROE - rentabilidad financiera (%) (obj + alto)</td>
          <td style="white-space: nowrap;"></td>
          <template v-for="(empresa,i) in comparadas">
            <td style="white-space: nowrap;border-right: 1px solid rgb(144, 144, 144)">{{ Math.round(empresa['11010193621'] || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} %</td>
            <td :style="'white-space: nowrap;' + (i == 0 ? 'border-right: 5px solid var(--v-primary-base)' : '')"></td>
            <template v-if="i != 0">
              <template v-if="Math.round(comparadas[0]['11010193621'] || 0) >= Math.round(empresa['11010193621'] || 0)">
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193621'] || 0) - Math.round(empresa['11010193621'] || 0)) / (Math.round(empresa['11010193621'] || 0) || 1)) * 100) }} %</td>
              </template>
              <template v-else>
                  <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"></td>
                  <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((Math.round(comparadas[0]['11010193621'] || 0) - Math.round(empresa['11010193621'] || 0)) / (Math.round(empresa['11010193621'] || 0) || 1)) * 100) }} %</td>
              </template>
            </template>
          </template>
          <td style="white-space: nowrap;">{{ Math.round(nuevo['11010193621'] || 0) }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <SelectEstudio :estudios="estudios" :comparadas="comparadas" ref="selectEstudio"></SelectEstudio>
    <NuevoEstudio ref="estudio"></NuevoEstudio>
    <NewCif ref="newCif"></NewCif>
    
    <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
      <v-btn color="primary" fab @click="exportar" large class="me-4">
        <v-icon>mdi-microsoft-excel</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" fab @click="addEstudio" large class="me-4">
        <v-icon>mdi-upload</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" fab @click="addComparada" large>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import * as xlsx from 'xlsx';
import FileSaver from 'file-saver';
import * as ExcelJS from "exceljs";

export default {
  components: {
    SelectEstudio: () => import("../components/SelectEstudio.vue"),
    NuevoEstudio: () => import("../components/NuevoEstudio.vue"),
    NewCif: () => import("../components/NewCif.vue"),
  },
  data(){
    return {
      estudios:[],
      comparadas:[],
      nuevo: {},
      noHint: true,
      editingIs: null,
      editingName: null,
    }
  },
  methods:{
    exportar(){
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('COMPARACIÓN');
      let startCol = 3
      var newColValues1 = [
          null,
          null,
          "CUENTA PyG",
          "VENTAS",
          "Aprovisionamientos",
          "Margen Bruto",
          "Otros Ingresos Explotación",
          "Gastos Personal",
          "Otros Costes de Explotación y Gastos Generales",
          "Imputación Subvención Inmov. No financiero",
          "Otros Resultados / Resultados Extraordinarios",
          "EBITDA",
          "Amortización del Inmovilizado",
          "EBIT (Resultado de Explotación)",
          "Resultado Financiero",
          "BAI (Beneficio Antes de Impuestos)",
          "Impuesto sobre beneficios (IS Sociedades)",
          "BENEFICIO NETO (Rtdo Ejercicio)",
          "PUNTO MUERTO",
          "Punto Muerto (€)",
          "CASH FLOWS",
          "Beneficio Neto",
          "Amortización del Inmovilizado",
          "Imputación Subvención Inmov. No financiero",
          "IS Sociedades (bases negativas)",
          "FCF OPERATIVO",
          "Amortización Financiera (Deuda Principal en 8 años)",
          "FREE CASH FLOWS",
          "BALANCE",
          "Efectivo (y múltiplo EBITDA)",
          "Activo NO Corriente",
          "Existencias",
          "Clientes (Deudores)",
          "Patrimonio Neto",
          "Rtdo Ejercicios Anteriores",
          "Pasivo NO Corriente",
          "Deudas LP (con Entidades Financieras)",
          "Otras Deudas LP + Deudas empresas grupo",
          "Deudas CP",
          "Proveedores",
          "Otros Acreedores",
          "RATIOS",
          "ratios equilibrio y liquidez",
          "Fondo Maniobra (FM > 0)",
          "Ratio Solvencia (objetivo >1)",
          "Ratio de Liquidez (objetivo >1) (%)",
          "ratios endeudamiento",
          "Porcentaje Endeudamiento (%) (obj 40%-60%)",
          "Cobertura Servicio de la Deuda (obj <4)",
          "ratios operativos",
          "Ventas por Empleado (media) / Múltiplo s/coste empleado",
          "Coste por Empleado (medio)",
          "ratios resultados",
          "ROA - rentabilidad económica (%) (obj + alto)",
          "Rentabilidad de Explotación (%) (obj + alto)",
          "ROE - rentabilidad financiera (%) (obj + alto)"
        ]
      var newColValues2 = [
        null,
        null,
        "NOMBRE E-INFORMA",
        "40100 - 1. Importe neto de la cifra de negocios",
        "40400 - 4. Aprovisionamientos",
        null,
        "40500 - 5. Otros ingresos de explotación",
        "40600 - 6. Gastos de personal",
        "40700 - 7. Otros gastos de explotación",
        "40900 - 9. Imputación de subvenciones de inmovilizado no financiero y otras",
        "413001 - 2. Otros resultados",
        null,
        "40800 - 8. Amortización del inmovilizado",
        null,
        "49200 - B) RESULTADO FINANCIERO (13 + 14 + 15 + 16 + 17 + 18)",
        null,
        "419001 - 9. Impuestos sobre beneficios",
        null,
        "NOMBRE E-INFORMA",
        null,
        "NOMBRE E-INFORMA",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        "NOMBRE E-INFORMA",
        "12700 - VI. Efectivo y otros activos líquidos equivalentes",
        "11000 - A) ACTIVO NO CORRIENTE",
        "12200 - I. Existencias",
        "12300 - II. Deudores comerciales y otras cuentas a cobrar",
        "20000 - A) PATRIMONIO NETO",
        "21500 - V. Resultados de ejercicios anteriores",
        "31000 - B) PASIVO NO CORRIENTE",
        "31220 - 1. Deudas con entidades de crédito",
        null,
        "32300 - II. Deudas a corto plazo",
        "32580 - 1. Proveedores",
        "32590 - 2. Otros acreedores",
        "NOMBRE E-INFORMA",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null]
      sheet.getColumn(1).values = newColValues1
      sheet.getColumn(2).values = newColValues2
      for (let i = 0; i < this.comparadas.length; i++) {
        let empresa = this.comparadas[i]
        var col = [
          empresa.year,
          empresa.nombre,
          empresa.nombreCustom,
          (empresa['40100'] || 0),
          (empresa['40400'] || 0),
          Math.round(this.calculateSum(empresa, ['40400', '40100'])),
          (empresa['40500'] || 0),
          (empresa['40600'] || 0),
          (empresa['40700'] || 0),
          (empresa['40900'] || 0),
          (empresa['41300'] || 0),
          Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])),
          (empresa['40800'] || 0),
          Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])),
          (empresa['49200'] || 0),
          Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])),
          (empresa['41900'] || 0),
          Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])),
          null,
          Math.round(-(this.calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(Math.round(this.calculatePercentage(empresa, ['40400'], ['40100','40500'])) / 100))) || 1)),
          null,
          Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])),
          -(empresa['40800'] || 0),
          -(empresa['40900'] || 0),
          (empresa['isSociedades'] || 0),
          Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(empresa['40800'] || 0) + -(empresa['40900'] || 0)),
          Math.round(this.calculateSum(empresa, ['31220', '32300']) / 8),
          Math.round((this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(empresa['40800'] || 0) + -(empresa['40900'] || 0)) - Math.round(this.calculateSum(empresa, ['31220', '32300']) / 8)),
          null,
          (empresa['12700'] || 0),
          (empresa['11000'] || 0),
          (empresa['12200'] || 0),
          (empresa['12300'] || 0),
          (empresa['20000'] || 0),
          (empresa['21500'] || 0),
          (empresa['31000'] || 0),
          (empresa['31220'] || 0),
          Math.round(this.calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700'])),
          (empresa['32300'] || 0),
          (empresa['32580'] || 0),
          (empresa['32590'] || 0),
          null,
          null,
          Math.round((empresa['11000'] || 0) + (empresa['20000'] || 0) - (empresa['31000'] || 0)),
          Math.round((empresa['10000'] || 0) / (empresa['32000'] || 1)),
          Math.round((empresa['11010193606'] || 0) / 100) / 100,
          null,
          (empresa['11010193608'] || 0) / 100,
          (empresa['11010193610'] || 0) / 100,
          null,
          (empresa['11010193615'] || 0),
          (empresa['11010193616'] || 0),
          null,
          (empresa['11010193619'] || 0) / 100,
          (empresa['11010193620'] || 0) / 100,
          (empresa['11010193621'] || 0) / 100,
        ]
        sheet.getColumn(startCol).values = col
        sheet.getColumn(startCol).width = 16
        sheet.getColumn(startCol).eachCell((cell) => {
          cell.numFmt = "#,##0 €"
        })
        var col = [
          null,
          null,
          "RATIOS",
          1,
          Math.round(this.calculatePercentage(empresa, ['40400'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['40400','40100'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['40500'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['40600'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['40700'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['40900'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['41300'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300'], ['40100'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['40800'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['49200'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['41900'], ['40100','40500'])) / 100,
          Math.round(this.calculatePercentage(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'], ['40100','40500'])) / 100,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          Math.round((this.calculatePercentage(empresa, ['31220', '32300'], ['40100','40500']) / 8)) / 100,
          Math.round(((((this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(empresa['40800'] || 0) + -(empresa['40900'] || 0)) - Math.round(this.calculateSum(empresa, ['31220', '32300']) / 8)) / (((empresa['40100'] || 0) + (empresa['40500'] || 0)) || 1)))) / 100,
          null,
          (Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])) / (empresa['12700'] || 1)),
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          Math.round((empresa['11010193615'] || 0) / (empresa['11010193616'] || 1)) / 100,
          null,
          null,
          null,
          null,
          null,
        ]
        sheet.getColumn(startCol+1).values = col
        sheet.getColumn(startCol+1).width = 12
        sheet.getColumn(startCol+1).eachCell((cell) => {
          cell.numFmt = "#,##0 %"
        })
        if (i != 0){
          var col = [
            empresa.year,
            empresa.nombre,
            "DIF",
            (this.comparadas[0]['40100'] || 0) - (empresa['40100'] || 0),
            ((this.comparadas[0]['40400'] || 0) - (empresa['40400'] || 0)) * -1,
            Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100'])) - Math.round(this.calculateSum(empresa, ['40400', '40100'])),
            (this.comparadas[0]['40500'] || 0) - (empresa['40500'] || 0),
            ((this.comparadas[0]['40600'] || 0) - (empresa['40600'] || 0)) * -1,
            ((this.comparadas[0]['40700'] || 0) - (empresa['40700'] || 0)) * -1,
            (this.comparadas[0]['40900'] || 0) - (empresa['40900'] || 0),
            (this.comparadas[0]['41300'] || 0) - (empresa['41300'] || 0),
            Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])),
            ((this.comparadas[0]['40800'] || 0) - (empresa['40800'] || 0)) * -1,
            Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])),
            ((this.comparadas[0]['49200'] || 0) - (empresa['49200'] || 0)) * -1,
            Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])),
            ((this.comparadas[0]['41900'] || 0) - (empresa['41900'] || 0)) * -1,
            Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])),
            null,
            Math.round(-(this.calculateSum(this.comparadas[0], ['40800', '40700', '40600'])) / ((1-(-(Math.round(this.calculatePercentage(this.comparadas[0], ['40400'], ['40100','40500'])) / 100))) || 1)) - Math.round(-(this.calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(Math.round(this.calculatePercentage(empresa, ['40400'], ['40100','40500'])) / 100))) || 1)),
            null,
            Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])),
            -(this.comparadas[0]['40800'] || 0) - -(empresa['40800'] || 0),
            -(this.comparadas[0]['40900'] || 0) - -(empresa['40900'] || 0),
            (this.comparadas[0]['isSociedades'] || 0) - (empresa['isSociedades'] || 0),
            Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(this.comparadas[0]['40800'] || 0) + -(this.comparadas[0]['40900'] || 0)) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(empresa['40800'] || 0) + -(empresa['40900'] || 0)),
            (Math.round(this.calculateSum(this.comparadas[0], ['31220', '32300']) / 8) - Math.round(this.calculateSum(empresa, ['31220', '32300']) / 8)) * -1,
            Math.round((this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(this.comparadas[0]['40800'] || 0) + -(this.comparadas[0]['40900'] || 0)) - Math.round(this.calculateSum(this.comparadas[0], ['31220', '32300']) / 8)) - Math.round((this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(empresa['40800'] || 0) + -(empresa['40900'] || 0)) - Math.round(this.calculateSum(empresa, ['31220', '32300']) / 8)),
            null,
            (this.comparadas[0]['12700'] || 0) - (empresa['12700'] || 0),
            (this.comparadas[0]['11000'] || 0) - (empresa['11000'] || 0),
            (this.comparadas[0]['12200'] || 0) - (empresa['12200'] || 0),
            (this.comparadas[0]['12300'] || 0) - (empresa['12300'] || 0),
            (this.comparadas[0]['20000'] || 0) - (empresa['20000'] || 0),
            (this.comparadas[0]['21500'] || 0) - (empresa['21500'] || 0),
            (this.comparadas[0]['31000'] || 0) - (empresa['31000'] || 0),
            (this.comparadas[0]['31220'] || 0) - (empresa['31220'] || 0),
            Math.round(this.calculateSum(this.comparadas[0], ['31300', '31400', '31500', '31600', '31700'])) - Math.round(this.calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700'])),
            (this.comparadas[0]['32300'] || 0) - (empresa['32300'] || 0),
            (this.comparadas[0]['32580'] || 0) - (empresa['32580'] || 0),
            (this.comparadas[0]['32590'] || 0) - (empresa['32590'] || 0),
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            (this.comparadas[0]['11010193615'] || 0) - (empresa['11010193615'] || 0),
            (this.comparadas[0]['11010193616'] || 0) - (empresa['11010193616'] || 0),
            null,
            null,
            null,
            null,
          ]
          sheet.getColumn(startCol+2).values = col
          sheet.getColumn(startCol+2).width = 16
          let negativos = [5,8,9,13,15,17,27]
          sheet.getColumn(startCol+2).eachCell((cell,i) => {
            if (negativos.includes(i)) cell.numFmt = "[Red]#,##0 €;[Green]-#,##0 €;0 €"
            else cell.numFmt = "[Green]#,##0 €;[Red]-#,##0 €;0 €"
          })
          var col =[
            null, 
            null, 
            null, 
            Math.round((((this.comparadas[0]['40100'] || 0) - (empresa['40100'] || 0)) / ((empresa['40100'] || 1))) * 100) / 100,
            Math.round((((this.comparadas[0]['40400'] || 0) - (empresa['40400'] || 0)) / ((empresa['40400'] || 1))) * 100) / 100, 
            Math.round((Math.round(Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100'])) - Math.round(this.calculateSum(empresa, ['40400', '40100']))) / (Math.round(Math.round(this.calculateSum(empresa, ['40400', '40100'])) || 1))) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['40500'] || 0) - (empresa['40500'] || 0)) / ((empresa['40500'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['40600'] || 0) - (empresa['40600'] || 0)) / ((empresa['40600'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['40700'] || 0) - (empresa['40700'] || 0)) / ((empresa['40700'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['40900'] || 0) - (empresa['40900'] || 0)) / ((empresa['40900'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['41300'] || 0) - (empresa['41300'] || 0)) / ((empresa['41300'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300']))) / (Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300'])) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['40800'] || 0) - (empresa['40800'] || 0)) / ((empresa['40800'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800']))) / (Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800'])) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['49200'] || 0) - (empresa['49200'] || 0)) / ((empresa['49200'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200']))) / (Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200'])) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['41900'] || 0) - (empresa['41900'] || 0)) / ((empresa['41900'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))) / (Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) || 1)) * 100) / 100, 
            null, 
            Math.round(((Math.round(-(this.calculateSum(this.comparadas[0], ['40800', '40700', '40600'])) / ((1-(-(this.calculatePercentage(this.comparadas[0], ['40400'], ['40100','40500'])))) || 1)) - Math.round(-(this.calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(this.calculatePercentage(empresa, ['40400'], ['40100','40500'])))) || 1))) / (Math.round(-(this.calculateSum(empresa, ['40800', '40700', '40600'])) / ((1-(-(this.calculatePercentage(empresa, ['40400'], ['40100','40500'])))) || 1)) || 1)) * 100) / 100, 
            null, 
            Math.round(((Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']))) / (Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900'])) || 1)) * 100) / 100, 
            Math.round(((Math.round(-(this.comparadas[0]['40800'] || 0)) - (-(empresa['40800'] || 0))) / ((-(empresa['40800'] || 0)) || 1)) * 100) / 100, 
            Math.round(((Math.round(-(this.comparadas[0]['40900'] || 0)) - (-(empresa['40900'] || 0))) / ((-(empresa['40900'] || 0)) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['isSociedades'] || 0) - (empresa['isSociedades'] || 0)) / ((empresa['isSociedades'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(this.comparadas[0]['40800'] || 0) + -(this.comparadas[0]['40900'] || 0)) - Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(empresa['40800'] || 0) + -(empresa['40900'] || 0))) / (Math.round(this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(empresa['40800'] || 0) + -(empresa['40900'] || 0)) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.calculateSum(this.comparadas[0], ['31220', '32300'])) - Math.round(this.calculateSum(empresa, ['31220', '32300']))) / (Math.round(this.calculateSum(empresa, ['31220', '32300'])) || 1)) * 100) / 100, 
            Math.round(((Math.round((this.calculateSum(this.comparadas[0], ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(this.comparadas[0]['40800'] || 0) + -(this.comparadas[0]['40900'] || 0)) - (this.comparadas[0]['isSociedades'] || 0)) - Math.round((this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(empresa['40800'] || 0) + -(empresa['40900'] || 0)) - Math.round(this.calculateSum(empresa, ['31220', '32300']) / 8))) / (Math.round((this.calculateSum(empresa, ['40400', '40100', '40500', '40600', '40700', '40900', '41300', '40800', '49200', '41900']) + -(empresa['40800'] || 0) + -(empresa['40900'] || 0)) - Math.round(this.calculateSum(empresa, ['31220', '32300']) / 8)) || 1)) * 100) / 100, 
            null, 
            Math.round(((Math.round(this.comparadas[0]['12700'] || 0) - (empresa['12700'] || 0)) / ((empresa['12700'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['11000'] || 0) - (empresa['11000'] || 0)) / ((empresa['11000'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['12200'] || 0) - (empresa['12200'] || 0)) / ((empresa['12200'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['12300'] || 0) - (empresa['12300'] || 0)) / ((empresa['12300'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['20000'] || 0) - (empresa['20000'] || 0)) / ((empresa['20000'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['21500'] || 0) - (empresa['21500'] || 0)) / ((empresa['21500'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['31000'] || 0) - (empresa['31000'] || 0)) / ((empresa['31000'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['31220'] || 0) - (empresa['31220'] || 0)) / ((empresa['31220'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.calculateSum(this.comparadas[0], ['31300', '31400', '31500', '31600', '31700'])) - Math.round(this.calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700']))) / (Math.round(this.calculateSum(empresa, ['31300', '31400', '31500', '31600', '31700'])) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['32300'] || 0) - (empresa['32300'] || 0)) / ((empresa['32300'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['32580'] || 0) - (empresa['32580'] || 0)) / ((empresa['32580'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['32590'] || 0) - (empresa['32590'] || 0)) / ((empresa['32590'] || 0) || 1)) * 100) / 100, 
            null, 
            null, 
            Math.round(((Math.round((this.comparadas[0]['11000'] || 0) + (this.comparadas[0]['20000'] || 0) - (this.comparadas[0]['31000'] || 0)) - Math.round((empresa['11000'] || 0) + (empresa['20000'] || 0) - (empresa['31000'] || 0))) / (Math.round((empresa['11000'] || 0) + (empresa['20000'] || 0) - (empresa['31000'] || 0)) || 1)) * 100) / 100, 
            Math.round(((Math.round((this.comparadas[0]['10000'] || 0) / (this.comparadas[0]['32000'] || 1)) - Math.round((empresa['10000'] || 0) / (empresa['32000'] || 1))) / (Math.round((empresa['10000'] || 0) / (empresa['32000'] || 1)) || 1)) * 100) / 100, 
            Math.round(((Math.round((this.comparadas[0]['11010193606'] || 0) / 100) - Math.round((empresa['11010193606'] || 0) / 100)) / (Math.round((empresa['11010193606'] || 0) / 100) || 1)) * 100) / 100, 
            null, 
            Math.round(((Math.round(this.comparadas[0]['11010193608'] || 0) - (empresa['11010193608'] || 0)) / ((empresa['11010193608'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['11010193610'] || 0) - (empresa['11010193610'] || 0)) / ((empresa['11010193610'] || 0) || 1)) * 100) / 100, 
            null, 
            Math.round(((Math.round(this.comparadas[0]['11010193615'] || 0) - (empresa['11010193615'] || 0)) / ((empresa['11010193615'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['11010193616'] || 0) - (empresa['11010193616'] || 0)) / ((empresa['11010193616'] || 0) || 1)) * 100) / 100, 
            null, 
            Math.round(((Math.round(this.comparadas[0]['11010193619'] || 0) - (empresa['11010193619'] || 0)) / ((empresa['11010193619'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['11010193620'] || 0) - (empresa['11010193620'] || 0)) / ((empresa['11010193620'] || 0) || 1)) * 100) / 100, 
            Math.round(((Math.round(this.comparadas[0]['11010193621'] || 0) - (empresa['11010193621'] || 0)) / ((empresa['11010193621'] || 0) || 1)) * 100) / 100, 
          ]
          sheet.getColumn(startCol+3).values = col
          sheet.getColumn(startCol+3).width = 12
          
          sheet.getColumn(startCol+3).eachCell((cell,i) => {
            if (negativos.includes(i)) cell.numFmt = "[Red]#,##0 %;[Green]-#,##0 %;0 %"
            else cell.numFmt = "[Green]#,##0 %;[Red]-#,##0 %;0 %"
          })
          sheet.mergeCells(1, startCol, 1, startCol + 3);
          sheet.mergeCells(2, startCol, 2, startCol + 3);
          sheet.mergeCells(3, startCol + 2, 3, startCol + 3);
          startCol += 4;
        }
        else{
          sheet.mergeCells(1, startCol, 1, startCol + 1);
          sheet.mergeCells(2, startCol, 2, startCol + 1);
          startCol += 2;
        }
        sheet.getColumn(startCol-1).eachCell((cell) => {
          cell.border = {
            right: { style: 'thick', color: { argb: 'FF000000' } }
          }
        })
        let r = 4
        for(let i = 0; i < this.comparadas.length; i++){
          sheet.getRow(3).getCell(r).border = {
            right: { style: 'thick', color: { argb: 'FF000000' } }
          }
          if (i == 0) r += 3
          else r += 4
        }

      }
      sheet.getColumn(1).width = 40
      sheet.getColumn(2).hidden = true
      sheet.getRow(1).eachCell((cell) => {
        cell.numFmt = "##0"
        cell.alignment = { horizontal : 'center' }
      })
      sheet.getRow(45).eachCell((cell) => {
          cell.numFmt = "#,##0"
        })
      sheet.getRow(46).eachCell((cell) => {
          cell.numFmt = "#,##0 %"
        })
        sheet.getRow(48).eachCell((cell) => {
          cell.numFmt = "#,##0 %"
        })
        sheet.getRow(49).eachCell((cell) => {
          cell.numFmt = "#,##0 %"
        })
        sheet.getRow(54).eachCell((cell) => {
          cell.numFmt = "#,##0 %"
        })
        sheet.getRow(55).eachCell((cell) => {
          cell.numFmt = "#,##0 %"
        })
        sheet.getRow(56).eachCell((cell) => {
          cell.numFmt = "#,##0 %"
        })
        let val = 4
        let vals = []
        for (let i = 0; i < this.comparadas.length; i++){
          vals.push(val)
          val += (i === 0 ? 2 : 4)
        }
        sheet.getRow(3).eachCell((cell,i) => {
          cell.font = { 
            size:20,
            bold:true,
            color:{argb: 'ffd39652'}
          }
          if (!cell.border) cell.border = {}
          cell.border.top = { style: 'thick', color: { argb: 'FF000000' } }
        })
        sheet.getRow(2).eachCell((cell) => {
            cell.font = { size:16 }
          })
        sheet.getRow(43).eachCell((cell) => {
          cell.font = { size:20, color:{argb: 'ffd39652'} }
        })
        sheet.getRow(47).eachCell((cell) => {
          cell.font = { size:20, color:{argb: 'ffd39652'} }
        })
        sheet.getRow(50).eachCell((cell) => {
          cell.font = { size:20, color:{argb: 'ffd39652'} }
        })
        sheet.getRow(53).eachCell((cell) => {
          cell.font = { size:20, color:{argb: 'ffd39652'} }
      })
      sheet.getRow(1).eachCell((cell) => {
        cell.alignment = { horizontal: 'center' }
        cell.font = { size:20 }
      })

      let start = 3
      for (let i = 0; i < this.comparadas.length; i++) {
        console.log(start+1);
        if (!sheet.getRow(5).getCell(start+1).font) sheet.getRow(5).getCell(start+1).font = {}
        sheet.getRow(5).getCell(start+1).font.color = {argb : 'ffff0000'} 
        if (!sheet.getRow(8).getCell(start+1).font) sheet.getRow(8).getCell(start+1).font = {}
        sheet.getRow(8).getCell(start+1).font.color = {argb : 'ffff0000'} 
        if (!sheet.getRow(9).getCell(start+1).font) sheet.getRow(9).getCell(start+1).font = {}
        sheet.getRow(9).getCell(start+1).font.color = {argb : 'ffff0000'} 
        if (!sheet.getRow(13).getCell(start+1).font) sheet.getRow(13).getCell(start+1).font = {}
        sheet.getRow(13).getCell(start+1).font.color = {argb : 'ffff0000'} 
        if (!sheet.getRow(15).getCell(start+1).font) sheet.getRow(15).getCell(start+1).font = {}
        sheet.getRow(15).getCell(start+1).font.color = {argb : 'ffff0000'} 
        if (!sheet.getRow(17).getCell(start+1).font) sheet.getRow(17).getCell(start+1).font = {}
        sheet.getRow(17).getCell(start+1).font.color = {argb : 'ffff0000'} 
        if (!sheet.getRow(27).getCell(start+1).font) sheet.getRow(27).getCell(start+1).font = {}
        sheet.getRow(27).getCell(start+1).font.color = {argb : 'ffff0000'}
        sheet.getRow(27).getCell(start+1).font.size = 16 
        if(i == 0) start += 2
        else start += 4
      }
      for (let i = 1; i <= this.comparadas.length * 4; i++){
        sheet.getRow(4).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(6).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(12).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(16).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(18).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(20).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(26).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(28).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(30).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(33).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(34).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(37).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(39).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(51).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(54).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        sheet.getRow(56).getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' }
        };
        if (!sheet.getRow(56).getCell(i).border) sheet.getRow(56).getCell(i).border = {}
        sheet.getRow(56).getCell(i).border.bottom = { style: 'thick', color: { argb: 'FF000000' } }
      }

      sheet.getRow(4).eachCell((cell) => {
        cell.font = { size:16 }
      })

        sheet.getRow(6).eachCell((cell) => {
          cell.font = { size:16 }
        })

        sheet.getRow(12).eachCell((cell) => {
          cell.font = { size:16 }
        })

        sheet.getRow(16).eachCell((cell) => {
          cell.font = { size:16 }
        })

        sheet.getRow(18).eachCell((cell) => {
          cell.font = { size:16 }
        })

        sheet.getRow(20).eachCell((cell) => {
          cell.font = { size:16 }
        })

        sheet.getRow(22).eachCell((cell) => {
          cell.font = { size:16 }
        })

        sheet.getRow(26).eachCell((cell) => {
          cell.font = { size:16 }
        })

        sheet.getRow(28).eachCell((cell) => {
          cell.font = { size:16 }
        })
        sheet.getRow(30).eachCell((cell) => {
          cell.font = { size:16 }
        })

        sheet.getRow(33).eachCell((cell) => {
          cell.font = { size:16 }
        })

        sheet.getRow(34).eachCell((cell) => {
          cell.font = { size:16 }
        })

        sheet.getRow(37).eachCell((cell) => {
          cell.font = { size:16 }
        })

        sheet.getRow(39).eachCell((cell) => {
          cell.font = { size:16 }
        })
        
      sheet.getColumn(1).eachCell((cell,i) => {
        cell.alignment = { horizontal: 'left' }
        if([19,21,29,42].includes(i)){
          cell.font = { size:20, color:{argb: 'ffd39652'}, bold: true, underline: true }
        }
        if(i == 30) {
          cell.note = {
            texts: [
              { text: 'Tesorería + If cp (f.inversion 55k)' }
            ],
            author: 'Gestor Eventos'
          };
        }
        else if(i == 32) {
          cell.note = {
            texts: [
              { text: 'MÁS EXISTENCIAS => MÁS BENEFICIO' }
            ],
            author: 'Gestor Eventos'
          };
        }
        else if(i == 44) {
          cell.note = {
            texts: [
              { text: '(Capitales Permanentes: Patrimonio Neto + PnC > AnC).' },
            ],
            author: 'Gestor Eventos'
          };
        }
        else if(i == 45) {
          cell.note = {
            texts: [
              { text: 'TOTAL ACTIVO / PASIVO CORRIENTE.' },
              { text: 'Es la capacidad que tiene la empresa de hacer frente a sus obligaciones de pago (si una empresa tendría activos suficientes para pagar todas sus deudas u obligaciones de pago).' },
            ],
            author: 'Gestor Eventos'
          };
        }
        else if(i == 46) {
          cell.note = {
            texts: [
              { text: 'Si la empresa dispone o no de efectivo para hacer frente a sus deudas y obligaciones a CP.' },
              { text: 'ACTIVO CORRIENTE / PASIVO CORRIENTE.' },
              { text: 'El ratio indica las veces que puedes devolver tus obligaciones a CP.' },
            ],
            author: 'Gestor Eventos'
          };
        }
        else if(i == 48) {
          cell.note = {
            texts: [
              { text: 'Deuda Financiera LP+CP / TOTAL ACTIVO.' },
              { text: 'Indica el % de endeudamiento sobre el total del activo (bienes).' },
            ],
            author: 'Gestor Eventos'
          };
        }
        else if(i == 49) {
          cell.note = {
            texts: [
              { text: '(Deuda CP+LP) + (Deudas E  grupo CP + LP) / Cash Flow.' },
              { text: 'INDICA EL Nº DE VECES QUE NECESITAS USAR EL CASH FLOW PARA PAGAR TODA LA DEUDA FINANCIERA (cuanto menor es, mejor ratio).' },
              { text: 'El ratio de cobertura del servicio de la deuda indica la capacidad de la empresa de hacer frente a su endeudamiento no comercial a través de los recursos líquidos generados en el ejercicio de su actividad.' },
              { text: 'Indicador financiero que indica las veces que con el flujo de caja anual (cash flows) puede hacer frente al servicio total de la deuda (capital + intereses).' },
            ],
            author: 'Gestor Eventos'
          };
        }
        else if(i == 51) {
          cell.note = {
            texts: [
              { text: 'VENTAS / PLANTILLA MEDIA.' },
            ],
            author: 'Gestor Eventos'
          };
        }
        else if(i == 52) {
          cell.note = {
            texts: [
              { text: 'GASTOS PERSONAL / PLANTILLA MEDIA.' },
            ],
            author: 'Gestor Eventos'
          };
        }
        else if(i == 54) {
          cell.note = {
            texts: [
              { text: 'RTDO DE EXPLOTACIÓN / TOTAL ACTIVO.' },
              { text: 'Indica la generación de beneficios de las operaciones de la empresa a partir de la utilización de sus activos.' }
            ],
            author: 'Gestor Eventos'
          };
        }
        else if(i == 55) {
          cell.note = {
            texts: [
              { text: 'Valora con la utilización de sus activos, la generación de Beneficios Brutos de explotación (antes de int, is, amortizaciones y depreciaciones).' },
              { text: 'Más alto, mayor uso efectivo.' }
            ],
            author: 'Gestor Eventos'
          };
        }
        else if(i == 56) {
          cell.note = {
            texts: [
              { text: 'BAI / FONDOS PROPIOS.' },
              { text: 'Rentabilidad del Accionista. Cociente entre los Beneficios antes de impuestos con los fondos propios de la empresa.' }
            ],
            author: 'Gestor Eventos'
          };
        }
      })
      for (let i = 1; i <= this.comparadas.length*4; i++) {
        if (!sheet.getRow(3).getCell(i).border) sheet.getRow(3).getCell(i).border = {}
        if (!sheet.getRow(19).getCell(i).border) sheet.getRow(19).getCell(i).border = {}
        if (!sheet.getRow(21).getCell(i).border) sheet.getRow(21).getCell(i).border = {}
        if (!sheet.getRow(29).getCell(i).border) sheet.getRow(29).getCell(i).border = {}
        if (!sheet.getRow(42).getCell(i).border) sheet.getRow(42).getCell(i).border = {}

        sheet.getRow(3).getCell(i).border.top = { style : 'thick', color : { argb : 'ff000000' }}
        sheet.getRow(19).getCell(i).border.top = { style : 'thick', color : { argb : 'ff000000' }}
        sheet.getRow(21).getCell(i).border.top = { style : 'thick', color : { argb : 'ff000000' }}
        sheet.getRow(29).getCell(i).border.top = { style : 'thick', color : { argb : 'ff000000' }}
        sheet.getRow(42).getCell(i).border.top = { style : 'thick', color : { argb : 'ff000000' }}
      }
      workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${"FINANCIERO - EXPORTADO EL " + (new Date().toLocaleDateString('default', { weekday: 'short' }).toUpperCase() + ' - ' + (('0' + new Date().getDate()).slice(-2)) + (new Date().toLocaleString('default', { month: 'short' }).toUpperCase())) + " - " + new Date().getFullYear()}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
    },
    async update(key, empresa){
      try {
        await axios({
          method: 'PUT',
          url: `${process.env.VUE_APP_API_URL}/competencia/${key}/${empresa.cif}/${empresa.year}`,
          data: empresa[key]
        })
        this.$root.$emit("snack", "Se ha actualizado el estudio correctamente");
        this.editingIs = null
        this.editingName = null
      } catch (err){
        this.saving = false;
        console.error(err);
        this.$root.$emit("snack", "No se ha podido actualizar el estudio");
      }
    },
    async saveNewEstudio(){
      if (!this.nuevo.cif) {
        const res = await this.$refs.newCif.open("CIF", "No se ha encontrado un CIF. Introdúcelo manualmente:")
        if (!res) return
      }
      try {
        await axios({
          method: 'POST',
          url: `${process.env.VUE_APP_API_URL}/competencia`,
          data: this.nuevo
        })
        this.$root.$emit("snack", "Se ha guardado el estudio correctamente");
        this.comparadas.push(JSON.parse(JSON.stringify(this.nuevo)));
        this.nuevo = {}
      } catch (err){
        this.saving = false;
        console.error(err);
        this.$root.$emit("snack", "No se ha podido guardar el estudio");
      }
    },
    parseNewEstudio(estudio) {
      const result = {};
      
      for (const key in estudio) {
        if (key == 'nombre'){ result.nombre = estudio.nombre.trim()}
        else if (key == 'nombreCustom'){ result.nombreCustom = estudio.nombreCustom.trim()}
        else if (key == 'cif'){ result.cif = estudio.cif.trim()}
        else{
          let value = String(estudio[key]).trim();
  
          const formatoEuropa = /^-?[0-9]+(\.[0-9]{3})*(,[0-9]+)?$/;
          const formatoAmerica = /^-?[0-9]+(,[0-9]{3})*(\.[0-9]+)?$/;
  
          if (formatoEuropa.test(value)) {
            value = value.replace(/\./g, '').replace(',', '.');
          } else if (formatoAmerica.test(value)) {
            value = value.replace(/,/g, '');
          } else {
            console.error(`Formato de numero incorrecto: ${value}`);
          }
          result[key] = Math.round(parseFloat(value));
        }
      }

      return result;
    },
    async addEstudio(){
      var { year, newEstudio } = await this.$refs.estudio.open('Nuevo Estudio')
      if (!year || !newEstudio) return
      this.handleFile(newEstudio, year)
    },
    async addComparada(){
      var adding = await this.$refs.selectEstudio.open()
      if (!adding) return
      this.comparadas = adding
    },
    calculateSum(empresa, keys) {
      return keys.reduce((sum, key) => sum + (empresa[key] || 0), 0);
    },
    calculatePercentage(empresa, keys, total) {
      const tot = total.reduce((acc, key) => acc + (empresa[key] || 0), 0);
      const sum = keys.reduce((acc, key) => acc + (empresa[key] || 0), 0);
      return tot ? (sum / tot) * 100 : 0;
    },
    handleFile(event, targetYear) {
      const file = event;
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: 'array' });
        // codigos pa buscar en la excel (para encontrar el valor de "11010193601 - Fondo de maniobra (€)" añade 11010193601 al array)
        const codigos = [
          '40100', '40400', '40500', '40600', '40700','40900','41300','40800','49200','41900','10000','32000', // hoja 2
          '12700', '11000', '12200', '12300', '20000','21500','31000','31220','31300','31400','31500','31600','31700', '32300', '32580', '32590', // hoja 1
          '11010193606', '11010193608', '11010193610', '11010193615', '11010193616','11010193619','11010193620','11010193621' // hoja 4
        ];
        const result = {};

        workbook.SheetNames.forEach((sheetName, i) => {
          // no necesito la tercera hoja
          if (i != 2){
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });

            // headerRow = fila donde estan las fechas del estudio. Luego la necesito pa sacar los datos del año que quieras y no del 2020 por defecto
            var headerRow = undefined
            if (i == 0) {
              let rowIndex = -1;
              for (let i = 0; i < jsonData.length; i++) {
                if (jsonData[i][1] == "ACTIVO") {
                  rowIndex = i;
                  break;
                }
              }
              headerRow = jsonData[rowIndex+2]
            }
            else {
              headerRow = jsonData[2]
            }
            if (i == 0) {
              result.nombre = jsonData[0][1].split(", Cif: 0")[0]
              result.nombreCustom = jsonData[0][1].split(", Cif: 0")[0]
              result.cif = jsonData[0][1].split(", Cif: 0")[1]
            }
            const yearColumnIndex = headerRow.findIndex(cell => {
              if (typeof cell === 'string') {
                // regex pa sacar el año
                const match = cell.match(/\b\d{2}\/\d{2}\/(\d{4})\b/);
                if (match) {
                  const year = parseInt(match[1], 10);
                  return year == targetYear;
                }
              }
              return false;
            });
            
            if (yearColumnIndex === -1) {
              console.error(`Sin datos para ${targetYear}.`);
              return;
            }

            jsonData.forEach(row => {
              var codeCell = row[1]
              codigos.forEach(code => {
                if (codeCell && codeCell.startsWith(code)) {
                  const value = row[yearColumnIndex];
                  result[code] = (value || '0');
                }
              });
            });
          }
        });
        this.$set(this.$data,'nuevo', this.parseNewEstudio(result))
        this.$set(this.nuevo,'year',targetYear)
      };
      reader.readAsArrayBuffer(file);
    },
    async getEstudios(){
      const { data } = await axios({
        url: `/competencia`,
      });
      this.estudios = data.sort((a,b)=> a.cif.localeCompare(b.cif))
    }
  },
  async mounted(){
    await Promise.all([
      this.getEstudios()
    ])
  }
};
</script>

<style>
.tab table{
  width: auto !important;
}
.noHint tr > *:nth-child(2) {
    display: none;
}
.tab.darkTable tbody tr:nth-child(even) td {
    background-color: rgb(50, 50, 50) !important;
}
.tab.lightTable tbody tr:nth-child(even) td {
    background-color: rgb(225, 225, 225) !important;
}
.tab.darkTable tbody tr:nth-child(odd) td {
    background-color: rgb(30, 30, 30) !important;
}
.tab.lightTable tbody tr:nth-child(odd) td {
    background-color: rgb(255, 255, 255) !important;
}
.noArrow input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.noArrow input::-webkit-outer-spin-button,
.noArrow input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>



<!-- 

plantilla para añadir comparativo

<template v-if="a >= b">
    <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-success-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(a - b).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
    <td style="width: 50px;white-space: nowrap;color:var(--v-success-base);border-right:5px solid var(--v-primary-base)"><v-icon color="success">mdi-menu-up</v-icon>{{ Math.round(((a - b) / (b || 1)) * 100) }} %</td>
</template>
<template v-else>
    <td style="border-left:1px solid rgb(144,144,144);width: 50px;white-space: nowrap;color:var(--v-error-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(a - b).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }} €</td>
    <td style="width: 50px;white-space: nowrap;color:var(--v-error-base);border-right:5px solid var(--v-primary-base)"><v-icon color="error">mdi-menu-down</v-icon>{{ Math.round(((a - b) / (b || 1)) * 100) }} %</td>
</template>

-->